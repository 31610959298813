<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field vdatepicker control has-icons-right">
                <v-date-picker
                  class="calendar_input"
                  clearable
                  mode="range"
                  v-model="dateFilter"
                  :input-props="{ placeholder: 'Fecha' }"
                  is-range
                  @input="onGetSales"
                />
                <span class="icon is-right">
                  <span class="material-icons text_primary_green">today</span>
                </span>
              </div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input class="input input_reset" type="text" placeholder="Buscador" v-model="searchText" @keyup="findText">
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="salesList"
                :loading="salesLoaderSpinner"
              >
                <div slot="status" slot-scope="scope">
                  <p :class="getStatusColor(scope.row.status_id)">
                    {{ scope.row.status_name }}
                  </p>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(salesTotalPages)"
              :action="paginator"
              :loading="salesLoaderSpinner"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment-timezone'
import numeral from 'numeral'
import utilities from '@/extras/utilities'

const PENDIENTE = 1
const EN_PROCESO = 2
const ENVIADO = 3

export default {
  name: 'MainSales',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  computed: {
    ...mapGetters(['salesList', 'salesLoaderSpinner', 'salesTotalPages'])
  },
  data () {
    return {
      page: 1,
      timer: null,
      searchText: '',
      dateFilter: {},
      columnsData: [
        {
          id: 'order_number',
          header: 'Nº de pedido',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-venta', params: { saleId: row.id } })
        },
        { id: 'customer', header: 'Cliente', accessor: (row) => `${row.name} ${row.last_name}` },
        { id: 'email', header: 'Correo electrónico' },
        { id: 'datePurchase', header: 'Fecha de compra', accessor: (row) => `${moment(row.created_at).tz('America/Mexico_City').format('DD/MMM/YYYY')}` },
        { id: 'total', header: 'Total de la venta', accessor: (row) => numeral(row.total).format('$0,0.00') },
        { id: 'status', name: 'status', header: 'Estatus' }
      ]
    }
  },
  methods: {
    ...mapActions(['getSales', 'csvDownloadSales']),
    findText () {
      // Clear timer
      clearTimeout(this.timer)
      // Initialize timer
      this.timer = setTimeout(() => {
        // Execute search
        this.paginator(1)
      }, 500)
    },
    paginator (page) {
      this.page = page
      this.onGetSales()
    },
    onGetSales () {
      let startDate = ''
      let endDate = ''

      if (utilities.objectValidate(this.dateFilter, 'start', false)) {
        startDate = moment(this.dateFilter.start).format('YYYY-MM-DD 00:00:00')
        endDate = moment(this.dateFilter.end).format('YYYY-MM-DD 00:00:00')
      }

      this.getSales({
        page: this.page,
        search: this.searchText,
        limit: 10,
        startDate,
        endDate
      })
    },
    getStatusColor (statusId) {
      if (statusId === PENDIENTE) {
        return 'text_yellow'
      } else if (statusId === EN_PROCESO) {
        return 'text_aqua_ligth'
      } else if (statusId === ENVIADO) {
        return 'text_green'
      } else {
        return 'text_cancel'
      }
    },
    async csvDownloadSalesAction () {
      const { payload } = await this.csvDownloadSales({ search: this.searchText, startDate: this.startDate, endDate: this.endDate })
      if (payload) {
        const exportName = 'Ventas.csv'
        const blob = new Blob([payload], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, exportName)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', exportName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }
      return payload
    }
  },
  mounted () {
    this.onGetSales()
  }
}
</script>

<style lang="scss" scoped>
  .vdatepicker {
    width: 250px;
  }
  select {
    width: 200px;
  }

  .level-item {
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
